import React, { useEffect, useState, useContext } from "react";
import SearchBox from '../../components/searchbox/SearchBox';
import uuid from 'react-uuid';
import {useParams, useHistory, useLocation } from "react-router-dom";
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faFilter, faSpinner, faCalendarDay, faGlobe, faBullhorn, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExtraInfoItem from '../venuelist/ExtraInfoItem';
import RemarkItem from '../venuelist/RemarkItem';
import Location from '../venuelist/Location';
import DayTime from '../daytime/DayTime';
import Filter from '../filter/Filter';
import StallList from '../stallList/StallList';
import Helper from '../../Helper.js';
//import VendorList from '../vendorList/VendorList';
import Moment from 'moment';
import './VenueDetail.scss';

import UrlContext from "../../urlContext";

const VenueDetail = (props) => {
    const { baseUrl, ex } = useContext(UrlContext);
    const { venueId } = useParams();
    const [lastActivePath] = props.pathData;
    const history = useHistory();
    const location = useLocation();
    const [currentVenue, setCurrentVenue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingStalls, setIsLoadingStalls] = useState(false);
    const [stalls, setStalls] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const { t } = useTranslation();
    const [isCancelled, setIsCancelled] = useState(false);
    const [isRelocated, setIsRelocated] = useState(false);
    const [cancellationReason, setCancellationReason] = useState(null);
    const [relocationReason, setRelocationReason] = useState(null);
    const [filterActivated, setFilterActivated] = useState(false);
    const [order, setOrder] = useState('ascendingName');
    const [filter, setFilter] = useState('selectCategory');
    const [isStallEmpty, setIsStallEmpty] = useState(true);
    const [didChange, setDidChange] = useState(false);
    const [locationFilter, setLocationFilter] = useState('selectLocation');

    //Loads and sets the venues based on the venueId
    //reruns everytime the baseUrl,ex or venueId
    useEffect(() => {
        //not pretty -- Better solution? (avoiding double requests)
        let mounted = true;
        const request = () => {
            //check if online

            if (window.navigator.onLine) {
                var url = new URL(`${baseUrl}/venues/`);

                const options = {
                    method: 'GET',
                    headers: new Headers({ 'Accept': 'application/json' })
                };

                var paramsString = new URLSearchParams();

                let params = [
                    { "property": "getUpcomingRelocations", "value": true },
                    { "property": "getUpcomingCancellations", "value": true },
                    { "property": "getFirstUpcomingEvent", "value": true },
                    { "property": "id", "value": venueId }
                ];

                paramsString.append('filter', JSON.stringify(params));

                //fetch and set the venues
                setIsLoading(true);
                fetch(url + '?' + paramsString, options)
                    .then(response => response.json())
                    .then(data => {
                        if (mounted === true) {
                            if (data.success === true) {
                                setIsLoading(false);
                                loadVenue(data.data);
                            } else {
                                setCurrentVenue(null);
                            }
                            setIsLoading(false);
                        }
                    });

            } else {
                //what to do when offline
            }
        };

        if (ex) {
            request();
        }

        //mounted exist so the data can not be set if you exit the page
        return () => mounted = false;
    }, [baseUrl, ex, venueId]);

    //Set if the venue is canceled or relocated
    //reruns everytime the currentVenue changes
    useEffect(() => {
        const setActiveCircumstances = () => {
            if (currentVenue.firstUpcomingEvent) {
                const eventStartDate = Date.parse(currentVenue.firstUpcomingEvent.start);

                const setActiveCircumstance = (arr, stateFn, stateReasonFn) => {
                    if (arr && arr.length > 0) {
                        for (let i = 0; i < arr.length; i++) {
                            if (Date.parse(arr[i].startDate) <= eventStartDate && eventStartDate <= Date.parse(arr[i].endDate)) {
                                stateFn(() => {
                                    return true;
                                });
                                stateReasonFn(() => {
                                    return arr[i].reason;
                                });
                                break;
                            }
                        }
                    }
                };
                //set the cancellation
                setActiveCircumstance(currentVenue.cancellations, setIsCancelled, setCancellationReason);

                //set the reloaction
                setActiveCircumstance(currentVenue.relocations, setIsRelocated, setRelocationReason);
            }
        };

        //set currentVenue.stalls as stalls
        if (currentVenue !== null && currentVenue.stalls !== null) {
            setStalls([...currentVenue.stalls]);
            setActiveCircumstances();
        }
    }, [currentVenue]);

    //Load and set the stalls
    //reruns everytime currentVenue, searchValue, venueId or baseUrl changes
    useEffect(() => {
        let mounted = true;
        if (currentVenue && currentVenue.stalls/* && currentVenue.venueType === 'FAIR'*/) {

            if (window.navigator.onLine) {

                var url = new URL(`${baseUrl}/fori/vendorstalldetail?venueId=${venueId}`);

                const options = {
                    method: 'GET',
                    headers: new Headers({ 'Accept': 'application/json' })
                };

                //Change the category to the right one (only for Meifoor)
                /*const mapCategory = (cat) => {
                    switch (cat) {
                        case "Grootvermaak":
                        case "A-attracties":
                        case "A":
                        case "B":
                        case "Opvullingsattractie":
                            cat = "Attractie";
                            break;
                        default:
                            break;
                    }

                    if (typeof cat === 'undefined') {
                        cat = '';
                    }

                    return cat;
                };*/

                //Change the category to the right one (only for Meifoor)
                /*const locationRemap = (location) => {
                    let newLocation = "";

                    if (location) {
                        switch (location) {
                            case "'t Zand":
                            case "'t Zand Vrijdagmarkt":
                            case "T'zand":
                                newLocation = "'t Zand";
                                break;
                            case "Koning Albert I-park":
                            case "Koning Albert-I Park":
                            case "Koning Albert-I park":
                            case "Koning Albert-I park ":
                            case "Koning Albertpark":
                            case "Koning Albertplein I-park":
                                newLocation = "Koning Albert I-park";
                                break;
                            default:
                                newLocation = location;
                                break;
                        }
                    }

                    return newLocation;
                };*/

                //loads and sets the stalls
                //loads only the stalls where the searchvalue is in the stallname
                setIsLoadingStalls(true);
                fetch(url, options)
                    .then(response => response.json())
                    .then(data => {
                        if (mounted === true) {
                            if (data.success === true) {
                                setIsLoadingStalls(false);
                                var serverData = data.data;
                                /*if (venueId === "96e092fc-d653-11e6-9b24-615897ebd569") {
                                  serverData.map((stall) => {
                                      return { ...stall, merkecategories: stall.merkecategories.map(obj => obj.name = mapCategory(obj.name)), location: stall.location = locationRemap(stall.location)};
                                  });
                                };*/
                                setStalls(serverData
                                    .filter(stall => {
                                        if (stall.name && stall.name !== null && stall.name !== '-') {
                                            return stall.name.toLowerCase().includes(searchValue.toLowerCase());
                                        } else if (stall.vendor.companyname && stall.vendor.companyname !== null) {
                                            return stall.vendor.companyname.toLowerCase().includes(searchValue.toLowerCase());
                                        } else if (stall.vendor.lastname && stall.vendor.lastname !== null && stall.vendor.firstname && stall.vendor.firstname !== null) {
                                            const vendorName = stall.vendor.firstname + " " + stall.vendor.lastname;
                                            return vendorName.toLowerCase().includes(searchValue.toLowerCase());
                                        } else if (stall.vendor.lastname && stall.vendor.lastname !== null) {
                                            return stall.vendor.lastname.toLowerCase().includes(searchValue.toLowerCase());
                                        } else if (stall.vendor.firstname && stall.vendor.firstname !== null) {
                                            return stall.vendor.firstname.toLowerCase().includes(searchValue.toLowerCase());
                                        } else {
                                            return false;
                                        }
                                    }));
                                setDidChange(false);
                                if (data.data.length > 0) {
                                    setIsStallEmpty(false);
                                }
                            } else {
                                setCurrentVenue(null);
                            }
                        }
                    });
            }
        }

        //mounted exist so the data can not be set if you exit the page
        return () => mounted = false;
    }, [currentVenue, searchValue, venueId, baseUrl]);

    //goes to the previous page based on if its a fair or market
    //if neither go to the last active path
    const handleBackClick = () => {
        if (currentVenue === null) {
            history.push('/');
        }
        else if (currentVenue.venueType === 'FAIR') {
            history.push('/fairs');
        }
        else if (currentVenue.venueType === 'MARKET' || lastActivePath !== location.pathname) {
            history.push('/');
        } else {
            history.push(lastActivePath);
        }
    };

    //sets the current venue
    //also change the bonnenUrl for the Meifoor
    const loadVenue = venueData => {
        let currentVenueData = venueData[0];
        if (currentVenueData.id === '96e092fc-d653-11e6-9b24-615897ebd569') {
          currentVenueData.bonnenUrl = 'https://fori.be/kortingsbonnen/meifoor';
        }
        setCurrentVenue(currentVenueData);
    };

    //returns the location of the venue
    const renderLocation = () => {
        const locations = currentVenue.location.split(/[;,]/);
        const originalLocations = locations.map(location => <Location key={uuid()} location={location} moved={isRelocated} context={currentVenue.context} />);

        let relocations = null;

        if (isRelocated) {
            relocations = currentVenue.relocations.map(relocation => <Location key={uuid()} location={relocation.newLocation} moved={!isRelocated} context={currentVenue.context} />);
        }
        return (
            <React.Fragment>
                {originalLocations}
                {relocations}
            </React.Fragment>
        );
    };

    //converts and returns the given date to the Day/Month/Year format
    const TimeConverter = (time) =>{
        return Moment(new Date(time)).format('DD/MM/YYYY');
    };

    //returns the dates (start and end date) to be rendered
    const renderTime = () => {
        return <span className="fair-date">
            <FontAwesomeIcon icon={faCalendarDay} className="4x back-icon" />
            <span className="fair-date-text">
                {TimeConverter(currentVenue.firstUpcomingEvent.start)} - {TimeConverter(currentVenue.firstUpcomingEvent.end)}
            </span>
        </span>;
    };

    //returns the extra info for the fair or the market to be rendered
    const extraInfo = () => {
        //returns the social media to be rendered for the market
        const SocialItem = (props) => {
            const { type, value } = props;
            let icon;
            let url;
            switch (type) {
                case 'facebook':
                    icon = faFacebook;
                    if (value.includes(`https://www.facebook.com/`)) { url = value; }
                    else { url = `https://www.facebook.com/${value}`; }
                    break;
                case 'instagram':
                    icon = faInstagram;
                    if (value.includes(`https://www.instagram.com/`)) { url = value; }
                    else { url = `https://www.instagram.com/${value}`; }
                    break;
                case 'twitter':
                    icon = faTwitter;
                    if (value.includes(`https://www.twitter.com/`)) { url = value; }
                    else { url = `https://www.twitter.com/${value}`; }
                    break;
                default:
                    icon = faGlobe;
                    url = Helper.getValidUrl(value);
                    break;
            }
            return <a href={url} rel="noreferrer" className="attraction-item-social-link" target="_blank"><FontAwesomeIcon icon={icon} className="1x {type}" /></a>;
        };

        //returns and renders the relocation or cancelation of the market
        const renderException = (active, reason, type) => {
            if (active && reason) {
                return <div className="extra-info-exception">
                    <RemarkItem type={type} />
                    <ExtraInfoItem type={type} reason={reason} />
                </div>;
            } else {
                return '';
            }
        };

        //returns the info for the market (the site, offer and oplan)
        const renderInfo = (venue) => {
            if (venue.offer || venue.website || venue.planId) {
                return <div className="extra-info-info">
                    {venue.offer && <ExtraInfoItem type="offer" offer={venue.offer} />}
                    {venue.website && <ExtraInfoItem type="website" website={venue.website} />}
                    {venue.planId && <ExtraInfoItem type="plan" planId={venue.planId} />}
                </div>;
            } else {
                return '';
            }
        };

        //Gives every social item that the market has to render
        const renderSocials = (socialObj) => {
            const socialArr = Object.keys(socialObj).map((key, index) => <SocialItem key={key} type={key} value={socialObj[key]} />);
            return <div className="attraction-item-social-urls">{socialArr}</div>;
        };

        //returns the promotions for the markets
        const renderPromo = () => {
            return <div className="attraction-item-promo">
                <div className="attraction-item-promo-block-title">Promos</div>
                {currentVenue.promotions.map((promo, index) => <div className="attraction-item-promo-promo" key={uuid()}><span className="stall-promo-title">Promo {index + 1}: {promo.title}</span><br />{promo.text}</div>)}
            </div>
        };

        //Creates and returns the voucher for the marker ot the Fair
        const renderBonnenUrl = () => {
            return <span className="fair-promotions">
              <FontAwesomeIcon icon={faBullhorn} className="4x back-icon" />
              <span className="fair-promotions-text">
                  <a href={currentVenue.bonnenUrl} target="blank">Bonnen</a>
              </span>
          </span>;
        }

        const renderHuntUrl = () => {
          return <span className="fair-promotions">
          <FontAwesomeIcon icon={faSearch} className="4x back-icon"/>
              <span className="fair-promotions-text">
                  <a href={`https://www.crito.be/${currentVenue.huntId}`} target="blank">Schattenjacht</a>
              </span>
          </span>;
        }

        if (!currentVenue.offer && !currentVenue.website && !currentVenue.plandId && !cancellationReason && !relocationReason) {
            if (currentVenue.location || currentVenue.firstUpcomingEvent) {
                return <div className="extra-info">
                    {currentVenue.location && renderLocation()}
                    {currentVenue.bonnenUrl && renderBonnenUrl()}
                    {currentVenue.firstUpcomingEvent && renderTime()}
                    {currentVenue.couponUrl && renderBonnenUrl()}
                    {currentVenue.huntId && renderHuntUrl()}
                </div>;
            } else {
                return '';
            }
        } else {
            return <div className="extra-info">
                {currentVenue.startHour && currentVenue.endHour && currentVenue.weekdays && <DayTime venue={currentVenue} />}
                {renderException(isCancelled, cancellationReason, "cancellation")}
                {renderException(isRelocated, relocationReason, "relocation")}
                {currentVenue.location && renderLocation()}
                {renderInfo(currentVenue)}
                {currentVenue.promotions && currentVenue.promotions.length > 0 && renderPromo()}
                {currentVenue.social && renderSocials(currentVenue.social)}
                {currentVenue.couponUrl && renderBonnenUrl()}
                {currentVenue.huntId && renderHuntUrl()}
            </div>;
        }
    };

    //set the functions for if you close the filter
    function closeFilter() {
        setFilterActivated(false);
        setFilter('selectCategory');
        setOrder('ascendingName');
    }

    //returns the spinner when loading
    const loader = () => {
        return <div className="venue-loading">
            <FontAwesomeIcon icon={faSpinner} className="venue-loading-spinner" />
        </div>
    }

    //returns the text for when the loader failerd/ failed to get the info for the fair/market
    const failedLoader = () => {
        return <div className={`venue-detail market`}>
            <div className="venue-detail-title">
                <FontAwesomeIcon icon={faArrowLeft} onClick={handleBackClick} className="4x back-icon" />
                <div className="venue-detail-title-text">
                    <span className="venue-detail-market-context">{t("loadFailed")}</span>
                </div>
            </div>
        </div>
    }

    //renders the stall/market with the given info
    //render the market name, context name and extra info if it is market
    //render the fair if it is fair and the filter if the filter is activated
    //if it is loading  display the spinner
    //if it can't be loaded tell it cant be loaded.
    if (currentVenue) {
        if (currentVenue.venueType === 'MARKET') {
            return <><div className={`venue-detail market`}>
                <div className="venue-detail-title">
                    <FontAwesomeIcon icon={faArrowLeft} onClick={handleBackClick} className="4x back-icon" />
                    <div className="venue-detail-title-text">
                        <span className="venue-detail-market-name">{currentVenue.name}</span>
                        <span className="venue-detail-market-context">{currentVenue.context.name}</span>
                    </div>
                </div>
                {filterActivated ? <Filter stalls={stalls} setFilter={setFilter} filter={filter} order={order} setOrder={setOrder} setLocationFilter={setLocationFilter} locationFilter={locationFilter} type={currentVenue.venueType} /> :
                    <>{extraInfo()}
                        {/*{currentVenue.venueType === 'MARKET' && !isStallEmpty ?
                             <div className="sticky-container">
                                <div className="filter-container">
                                    <SearchBox onchangefn={setSearchValue} inputName="search" defaultValue={searchValue} minLength={-1} buffer={0} didChange={setDidChange} />
                                    <FontAwesomeIcon icon={faFilter} className="stall-itemUpdate-filter" onClick={() => { setFilterActivated(true); setSearchValue(""); }} />
                                </div>
                                {isLoadingStalls || didChange ? loader() : <VendorList vendors={stalls} order={order} filter={filter} locationFilter={locationFilter} />}
                            </div> :
                            <>{isLoadingStalls && loader()}</>
                        }*/}
                    </>
                }
            </div>
            {
                filterActivated &&
                <div className="stall-filter-apply">
                    <button className="stall-filter-applyFilter" onClick={() => setFilterActivated(false)}>{t('applyFilter')}</button>
                    <button className="stall-filter-applyCancel" onClick={closeFilter}>{t('cancel')}</button>
                </div>
            }
            </>
        }
        else {
            return <><div className={`venue-detail fair`}>
                <div className="venue-detail-title">
                    <FontAwesomeIcon icon={faArrowLeft} onClick={filterActivated ? closeFilter : handleBackClick} className="4x back-icon" />
                    <div className="venue-detail-title-text">
                        <span className="venue-detail-market-name">{filterActivated ? t('fairFilter') : currentVenue.name}</span>
                        {!filterActivated && <span className="venue-detail-market-context">{currentVenue.context.name}</span>}
                    </div>
                </div>
                {filterActivated ? <Filter stalls={stalls} setFilter={setFilter} filter={filter} order={order} setOrder={setOrder} setLocationFilter={setLocationFilter} locationFilter={locationFilter} type={currentVenue.venueType}/> :
                    <>{extraInfo()}
                        {currentVenue.venueType === 'FAIR' && !isStallEmpty ?
                            <div className="sticky-container">
                                <div className="filter-container">
                                    <SearchBox onchangefn={setSearchValue} inputName="search" defaultValue={searchValue} minLength={-1} buffer={0} didChange={setDidChange} />
                                    <FontAwesomeIcon icon={faFilter} className="stall-itemUpdate-filter" onClick={() => { setFilterActivated(true); setSearchValue(""); }} />
                                </div>
                                {isLoadingStalls || didChange ? loader() : <StallList stalls={stalls} order={order} filter={filter} locationFilter={locationFilter} />}
                            </div>  :
                            <>{isLoadingStalls && loader()}</>
                        }
                    </>
                }
            </div>
                {filterActivated &&
                    <div className="stall-filter-apply">
                        <button className="stall-filter-applyFilter" onClick={() => setFilterActivated(false)}>{t('applyFilter')}</button>
                        <button className="stall-filter-applyCancel" onClick={closeFilter}>{t('cancel')}</button>
                    </div>}
            </>
        }
    } else {
        return isLoading ? loader() : failedLoader();
    };
};

export default VenueDetail;
